<template>
  <div class="admin-bulk-users">
    <div class="toolbar justify-content-between">
      <div>
        <i class="icon-button" :class="$config.icons.general.back" v-b-tooltip title="Back to Admin" @click="clickBack"></i>
      </div>
      <div>
      </div>
    </div>
    <div class="list-containter">
      <b-card>
        <b-card-header><h2>Bulk Add Users</h2></b-card-header>
          <b-card-body>
              <data-parser :fields="dataFields" @data="onData"></data-parser>
            <div class="green-divider"></div>
<!--            User Creation Preview -->
            <div v-if="userList">
              <b-table striped hover :items="userList" :fields="userFields"
                       th-class="bv-table-header"
                       class="bv-table">
                <!--   Select All Header             -->
                <template v-slot:head(selected)="row">
                  <b-form-checkbox v-model="selectAll" v-on:change="toggleSelectAll"></b-form-checkbox>
                </template>
                <!--Select Checkbox Row Element-->
                <template v-slot:cell(selected)="row">
                  <b-form-checkbox v-model="row.item.selected" v-on:change="selectChange(row.item)"></b-form-checkbox>
                </template>
                <template v-slot:cell(name)="row">
                  <b-input v-if="row.item.state === 'preview'" class="editable-row " v-model="row.item.name"></b-input>
                  <div v-if="row.item.state === 'created'" class="fixed-row">{{row.item.name}}</div>
                </template>
                <template v-slot:cell(email)="row">
                  <b-input v-if="row.item.state === 'preview'" class="editable-row " v-model="row.item.email"></b-input>
                  <div v-if="row.item.state === 'created'" class="fixed-row">{{row.item.email}}</div>
                </template>
                <template v-slot:cell(phone)="row">
                  <b-input v-if="row.item.state === 'preview'" class="editable-row " v-model="row.item.phone"></b-input>
                  <div v-if="row.item.state === 'created'" class="fixed-row">{{row.item.phone}}</div>
                </template>
                <template v-slot:cell(status)="row">
                  <div v-if="row.item.state === 'preview'" class="fixed-row">
                    <i class="fa fa-circle-o row-icon-preview" v-b-tooltip.hover title="Preview, Not Created!"></i>
                  </div>
                  <div v-if="row.item.state === 'created'" class="fixed-row">
                    <i class="fa fa-check-circle row-icon-complete" v-b-tooltip.hover title="Created!"></i>
                  </div>
                </template>
              </b-table>
              <b-button class="button" @click="clickCreate">Create Users!</b-button>
            </div>
          </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import * as ErrorHelper from '../../components/helpers/ErrorHelper'
// import * as DataProvider from '../../components/helpers/DataProvider'
import DataParser from '../../components/admin/DataParser'

export default {
  name: 'admin-bulk-users',
  components: {
    DataParser
  },
  data: function () {
    return {
      dataFields: ['Name', 'Email', 'Phone'],
      // User Creation Preview
      userFields: [
        {
          key: 'selected',
          label: '-'
        },
        { key: 'id',
          label: 'id#'
        },
        { key: 'name',
          label: 'Name',
          sortable: true
        },
        { key: 'email',
          label: 'Email',
          sortable: true
        },
        { key: 'phone',
          label: 'Phone #',
          sortable: true
        },
        {
          key: 'status',
          label: 'Status'
        }
      ],
      userList: null,
      selectAll: false
    }
  },
  methods: {
    clickBack: function () {
      this.$router.push({ path: '/admin' })
    },
    onData: async function (data) {
      console.log('Data Received: ', data)
      let fieldLengths = data.map(x => x.length)
      let emptyFields = fieldLengths.filter(x => x === 0)
      if (emptyFields.length > 0) {
        ErrorHelper.displayGeneralErrorToast(
          'One or more of the required columns are empty. Please make sure you have at least one column in Name, Email and Phone!', 'Empty Columns')
        return
      }
      let lengthCheck = fieldLengths.filter(x => x !== fieldLengths[0])
      if (lengthCheck.length > 0) {
        let response = await this.$bvModal.msgBoxConfirm('You\'ve placed more than one column into the fields containers. This is an unusual configuration, but if you did this on purpose then press OK!', {
          title: 'Confirm Column Selection',
          okVariant: 'warning',
          centered: true
        })
        if (!response) {
          return
        }
      }
      this.generatePreview(data)
    },
    generatePreview: async function (data) {
      this.userList = []
      let emailData = data.find(x => x.field === 'Email').data
      let nameData = data.find(x => x.field === 'Name').data
      let phoneData = data.find(x => x.field === 'Phone').data

      let maxLength = Math.max(emailData.length, nameData.length, phoneData.length)
      let minLength = Math.min(emailData.length, nameData.length, phoneData.length)
      if (minLength !== maxLength) {
        ErrorHelper.displayGeneralWarningToast('WARNING: Uneven column length. Some Data will be discarded!', 'Uneven Data Length')
      }
      console.log(minLength)
      // Work through each row up to the lower limit of data rows.
      for (let idx = 0; idx < minLength; idx++) {
        this.userList.push({
          id: '-',
          email: emailData[idx],
          name: nameData[idx],
          phone: phoneData[idx],
          state: 'preview',
          selected: false
        })
      }
      console.log('User List Generated: ', this.userList)
    },
    toggleSelectAll: function (event) {
      if (event) {
        this.userList.forEach(x => { x.selected = true })
      } else {
        this.userList.forEach(x => { x.selected = false })
      }
    },
    selectChange: function (user) {
      this.selectAll = false
    },
    clickCreate: async function () {
      console.log(this.userList)
      let countSelected = this.userList.reduce((total, item) => { return total + +item.selected }, 0)
      if (countSelected === 0) {
        this.toggleSelectAll(true)
        ErrorHelper.displayGeneralWarningToast('You didn\t select anything, so we selected everything for you! Click again to proceed!', 'No Selection Made')
        return
      }
      // TODO - Attach this to the API to actually create users.
      this.userList.forEach((user, idx) => {
        if (user.selected) {
          user.state = 'created'
          user.id = idx
        }
      })
    },
    clickHelp: function () {
      console.log('help')
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../variables';

  h5 {
    color: $theme-color-primary-3;
  }

  .admin-bulk-users {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2%;
    overflow-y: auto;
  }

  .list-containter {
    background: $theme-color-background-4;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
  }

  .toolbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 3rem;
  }

  .toolbar-small{
    width: 100%;
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .input-options {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .parse-errors {
    border-radius: 5px;
    margin: 5px;
    padding: 5px;
    background: #ffd3d3;
    text-align: left;
    color: $red;
  }

  .data-container {
    background: $theme-color-background-1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .data-header{
    color: black;
    background: #7bff6e;
    border-radius: 5px;
    text-align: center;
    font-weight: 700;
    padding: 5px;
    margin-bottom: 5px;
    min-width: 11rem;
  }

  .data-header-parsed {
    color: black;
    background: #ff8d8e;
    border-radius: 5px;
    text-align: center;
    font-weight: 700;
    padding: 5px;
    margin-bottom: 5px;
  }

  .data-col {
    width: 10rem;
    background: #2A2A2A;
    margin: 5px 2px;
    cursor: move;
  }

  .data-col-header {
    font-weight: 700;
  }

  .data-col-sample-header{
    font-size: 0.7rem;
    font-style: italic;
    border-bottom: 1px solid $theme-color-primary-3;
  }

  .data-col-sample{
    font-size: 1rem;
    font-style: italic;
  }

  .data-col-container {
    background: #6c757d;
    min-height: 5rem;
    padding: 5px;
    margin: 10px;
  }

  .unassigned-cols {
    display: flex;
    flex-direction: row;
  }

  .editable-row {
    display: block;
    width: 100%;
    font-size: 18px;
    color: #FFFFFF;
    vertical-align: middle;
    border-style: solid;
    border-width: 0px 0px 1px;
    border-color: #000 #000 #ababab;
    border-radius: 0px;
    background-color: transparent;
    font-family: "Open Sans", sans-serif;
    text-decoration: none;
    padding: 0;
    margin: 0;
  }

  .fixed-row {
    color: $theme-color-primary-3;
    width: 100%;
    font-size: 18px;
    vertical-align: middle;
  }

  .row-icon-preview {
    font-size: 1rem;
    color: $warning;
  }

  .row-icon-complete {
    font-size: 1rem;
    color: $success;
  }
</style>
