<template>
  <div class="data-parser">
<!--            CSV Data Input and Parse-->
    <h3>Input User Data
      <i class="fa fa-question-circle clickable" v-b-tooltip.hover title="Click for Help" @click="$bvModal.show('modal-help-input')"></i>
    </h3>
    <div class="parse-errors" v-if="parseErrors">
      <h4>Errors:</h4>
      <li v-for="error of parseErrors" v-bind:key="error.code">{{error.message}}</li>
    </div>
    <b-textarea v-model="inputText" rows="8"></b-textarea>
    <div class="toolbar-small">
      <b-button class="button" @click="clickProceed">Proceed</b-button>
      <div class="input-options">
        <div class="mr-4"><h5>Options:</h5></div>
        <div class="mr-3">
          <b-checkbox v-model="singleLineMode" v-b-tooltip.hover title="Is the Data all on a single line?"
          > Single Line Mode</b-checkbox>
        </div>
        <div class="mr-2">
          <label for="manual_cols" class="device-label">Items per Row</label>
          <input class="text-input" type="number" min="3" placeholder="" :disabled="!singleLineMode"
                 v-b-tooltip.hover title="Single Line Mode Only: How many values per user entry?"
                 v-model="manualColumns" id="manual_cols">
        </div>
      </div>
    </div>
<!--           Data Column Selection-->
    <div v-if="parsedData">
      <div class="green-divider"></div>
      <h3>Column Assignment
        <i class="fa fa-question-circle clickable" v-b-tooltip.hover title="Click for Help"
           @click="$bvModal.show('modal-help-parse')"></i>
      </h3>
      Drag Parsed Data Columns to Fields
      <div class="data-container" >
        <div class="data-col-container">
          <div class="data-header-parsed">Unassigned Fields</div>
          <draggable v-model="cols" draggable=".data-col" :group="{ name: 'cols'}" class="unassigned-cols">
            <div v-for="col in cols" class="data-col" v-bind:key="col.id">
              <div class="data-col-header">{{col.title}}</div>
              <div class="data-col-sample-header">Data Sample:</div>
              <div v-for="(data, idx) of col.sample" v-bind:key="idx">
                {{data}}
              </div>
              <div class="data-col-sample-header">Total:</div>
              {{col.data.length}} Rows
            </div>
          </draggable>
        </div>
      </div>
      <div>
        <i class="fa fa-angle-double-down"></i>
        <i class="fa fa-angle-double-down"></i>
        <i class="fa fa-angle-double-down"></i>
      </div>
      <div class="data-container" >
        <div class="data-col-container" v-for="fieldCol of fieldCols" :key="fieldCol.id">
          <div  class="data-header">{{fieldCol.title}}</div>
          <draggable v-model="fieldCol.data" draggable=".data-col" :group="{ name: 'cols'}">
            <div v-for="col in fieldCol.data" class="data-col" v-bind:key="col.id">
              <div class="data-col-header">{{col.title}}</div>
              <div class="data-col-sample-header">Data Sample:</div>
              <div v-for="(data, idx) of col.sample" v-bind:key="idx">
                {{data}}
              </div>
              <div class="data-col-sample-header">Total:</div>
              {{col.data.length}} Rows
            </div>
          </draggable>
        </div>
      </div>
    </div>
    <div class="toolbar-small" v-if="isDataInCols()">
      <b-button class="button" @click="clickOK">OK</b-button>
    </div>

    <!--    Data Input Help Modal Template-->
    <b-modal id="modal-help-input" centered class="modal-content" size="xl" hide-footer title="Data Input Help">
      <div class="mb-2">Enter User Data into the large Textbox at the top of the form. Data can be separated by commas, semicolons, tabs, etc.
        By default each new row of data is expected to be on a NEW LINE.</div>
      <h5>Example #1: Multi-Line (default)</h5>
      <div>Bob Smith, Bob@hotmail.com, 0411111111</div>
      <div>Roger Lemming, R-lemming@bmail.com, 042222222</div>
      <div>Some Guy, guy@test.com, 04333333</div>
      <div> If your data is NOT on a new line</div>
      <div class="my-2"> If your data is in a SINGLE LINE (i.e. does not have line returns between entries) then check the 'Single Line Mode'
      option and enter the number of columns that should be extracted for each entry:</div>
      <h5>Example #2: Single Line</h5>
      <div>Bob Smith, Bob@hotmail.com, 0411111111, Roger Lemming, R-lemming@bmail.com, 042222222, Some Guy, guy@test.com, 04333333</div>
      <div> For the above example we would set 3 as the number of columns as each entry has only Name, Email and Phone fields.</div>
      <b-button class="button" @click="$bvModal.hide('modal-help-input')">Close</b-button>
    </b-modal>

    <!--    Data Parsing Help Modal Template-->
    <b-modal id="modal-help-parse" centered class="modal-content" size="xl" hide-footer title="Data Parsing Help">
      <div class="mb-2">Your Data has been parsed into columns. Each column is numbered and shows a PREVIEW of the data it contains under the 'Data Sample'
        heading. If the Data is mixed together (i.e. usernames and email address appear in the same column), got back to the Input User Data section
        and try again. You may need to adjust the Items per Row if you are using single line mode.</div>

      <div>If the Columns appear correct, they can be dragged into the area below the GREEN FIELD NAMES that match their contents. By default the
      first three columns will be placed under NAME, EMAIL and PHONE. These can be swapped around by dragging if they do not match.</div>
      <b-button class="button" @click="$bvModal.hide('modal-help-parse')">Close</b-button>
    </b-modal>
  </div>
</template>

<script>
import * as ErrorHelper from '../../components/helpers/ErrorHelper'
import draggable from 'vuedraggable'

export default {
  name: 'data-parser',
  components: {
    draggable
  },
  props: ['fields'],
  data: function () {
    return {
      parseConfig: {
        delimiter: '', // auto-detect
        newline: '', // auto-detect
        quoteChar: '"',
        escapeChar: '"',
        header: false
      },
      singleLineMode: false,
      fieldCount: 0,
      manualColumns: 3,
      inputText: '',
      parseErrors: null,
      parsedData: null,
      // Draggable Columns
      cols: [],
      fieldCols: []
    }
  },
  created () {
    // Create Field containers for each field provided to the Fields property
    this.fields.forEach((field, idx) => {
      this.fieldCols.push({
        id: idx,
        title: field,
        data: []
      })
    })
    this.fieldCount = this.fields.length
  },
  methods: {
    clickProceed: function () {
      this.parseErrors = null
      let data = this.$papa.parse(this.inputText)
      console.log(data)
      if (data.errors.length > 0) {
        this.parseErrors = data.errors
      }

      if (data.data.length > 0) {
        this.parsedData = data.data
        if (this.singleLineMode) {
          this.createColumnsSingle(data.data)
        } else {
          this.createColumnsMulti(data.data)
        }
      }
    },
    createColumnsMulti: function (data) {
      let cols = []
      // Turn Data rows into columns
      data.forEach((row, rowIdx) => {
        row.forEach((rowItem, rowItemIdx) => {
          if (cols.length <= rowItemIdx) {
            cols.push({
              id: rowItemIdx,
              title: `Column ${rowItemIdx}`,
              data: [rowItem]
            })
          } else {
            cols[rowItemIdx].data.push(rowItem)
          }
        })
      })
      this.populateColumns(cols)
    },
    createColumnsSingle: function (data) {
      let columnCount = parseInt(this.manualColumns)
      if (columnCount < 3) {
        ErrorHelper.displayGeneralErrorToast('"Items Per Row" Value is to low. We need at least 3 items (Name, Phone, Email) to create users', 'Invalid Number of Manual Columns')
        return
      }
      if (data.length > 1) {
        ErrorHelper.displayGeneralWarningToast('WARNING: You\'ve selected SINGLE LINE MODE, but you have multiple lines of data! They will all be aggregated and treated as a single line.', 'Multiple Lines of Data')
      }
      let singleData = []
      data.forEach(row => { singleData = singleData.concat(row) }) // Aggregate data into a single array
      let cols = []
      for (let colIdx = 0; colIdx < columnCount; colIdx++) {
        cols.push({
          id: colIdx,
          title: `Column ${colIdx}`,
          data: []
        })
      }
      console.log('Columns: ', cols)
      let idx = 0
      singleData.forEach(value => {
        cols[idx].data.push(value)
        idx++
        if (idx === columnCount) {
          idx = 0
        }
      })
      this.populateColumns(cols)
    },
    populateColumns: function (cols) {
      // Reset Array Contents
      this.cols = []
      this.fieldCols.forEach(fc => { fc.data = [] })
      // Create Sample arrays of the first few values
      cols.forEach(col => {
        let max = Math.min(5, col.data.length)
        col.sample = col.data.slice(0, max)
      })
      console.log('Columns Generated: ', cols)
      this.cols = cols
      // Assign Data to Field Columns
      this.fieldCols.forEach(fc => {
        if (this.cols.length > 0) {
          fc.data.push(cols.shift())
        }
      })
    },
    isDataInCols: function () {
      return (this.fieldCols.filter(fc => fc.data.length === 0).length === 0)
    },
    clickOK: async function () {
      console.log('OK ', this.fieldCols)
      let dataResponse = []
      this.fieldCols.forEach(fieldCol => {
        let colData = []
        fieldCol.data.forEach(col => {
          colData = colData.concat(col.data)
        })
        dataResponse.push({
          field: fieldCol.title,
          data: colData
        })
      })
      this.$emit('data', dataResponse)
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../variables';

  h5 {
    color: $theme-color-primary-3;
  }

  .data-parser {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2%;
    overflow-y: auto;
  }

  .list-containter {
    background: $theme-color-background-4;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
  }

  .toolbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 3rem;
  }

  .toolbar-small{
    width: 100%;
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .input-options {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .parse-errors {
    border-radius: 5px;
    margin: 5px;
    padding: 5px;
    background: #ffd3d3;
    text-align: left;
    color: $red;
  }

  .data-container {
    background: $theme-color-background-1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .data-header{
    color: black;
    background: #7bff6e;
    border-radius: 5px;
    text-align: center;
    font-weight: 700;
    padding: 5px;
    margin-bottom: 5px;
    min-width: 11rem;
  }

  .data-header-parsed {
    color: black;
    background: #ff8d8e;
    border-radius: 5px;
    text-align: center;
    font-weight: 700;
    padding: 5px;
    margin-bottom: 5px;
  }

  .data-col {
    width: 10rem;
    background: #2A2A2A;
    margin: 5px 2px;
    cursor: move;
  }

  .data-col-header {
    font-weight: 700;
  }

  .data-col-sample-header{
    font-size: 0.7rem;
    font-style: italic;
    border-bottom: 1px solid $theme-color-primary-3;
  }

  .data-col-sample{
    font-size: 1rem;
    font-style: italic;
  }

  .data-col-container {
    background: #6c757d;
    min-height: 5rem;
    padding: 5px;
    margin: 10px;
  }

  .unassigned-cols {
    display: flex;
    flex-direction: row;
  }

  .editable-row {
    display: block;
    width: 100%;
    font-size: 18px;
    color: #FFFFFF;
    vertical-align: middle;
    border-style: solid;
    border-width: 0px 0px 1px;
    border-color: #000 #000 #ababab;
    border-radius: 0px;
    background-color: transparent;
    font-family: "Open Sans", sans-serif;
    text-decoration: none;
    padding: 0;
    margin: 0;
  }

  .fixed-row {
    color: $theme-color-primary-3;
    width: 100%;
    font-size: 18px;
    vertical-align: middle;
  }

  .row-icon-preview {
    font-size: 1rem;
    color: $warning;
  }

  .row-icon-complete {
    font-size: 1rem;
    color: $success;
  }
</style>
