var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "data-parser" },
    [
      _c("h3", [
        _vm._v("Input User Data "),
        _c("i", {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: { hover: true }
            }
          ],
          staticClass: "fa fa-question-circle clickable",
          attrs: { title: "Click for Help" },
          on: {
            click: function($event) {
              return _vm.$bvModal.show("modal-help-input")
            }
          }
        })
      ]),
      _vm.parseErrors
        ? _c(
            "div",
            { staticClass: "parse-errors" },
            [
              _c("h4", [_vm._v("Errors:")]),
              _vm._l(_vm.parseErrors, function(error) {
                return _c("li", { key: error.code }, [
                  _vm._v(_vm._s(error.message))
                ])
              })
            ],
            2
          )
        : _vm._e(),
      _c("b-textarea", {
        attrs: { rows: "8" },
        model: {
          value: _vm.inputText,
          callback: function($$v) {
            _vm.inputText = $$v
          },
          expression: "inputText"
        }
      }),
      _c(
        "div",
        { staticClass: "toolbar-small" },
        [
          _c(
            "b-button",
            { staticClass: "button", on: { click: _vm.clickProceed } },
            [_vm._v("Proceed")]
          ),
          _c("div", { staticClass: "input-options" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "mr-3" },
              [
                _c(
                  "b-checkbox",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true }
                      }
                    ],
                    attrs: { title: "Is the Data all on a single line?" },
                    model: {
                      value: _vm.singleLineMode,
                      callback: function($$v) {
                        _vm.singleLineMode = $$v
                      },
                      expression: "singleLineMode"
                    }
                  },
                  [_vm._v(" Single Line Mode")]
                )
              ],
              1
            ),
            _c("div", { staticClass: "mr-2" }, [
              _c(
                "label",
                { staticClass: "device-label", attrs: { for: "manual_cols" } },
                [_vm._v("Items per Row")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true }
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.manualColumns,
                    expression: "manualColumns"
                  }
                ],
                staticClass: "text-input",
                attrs: {
                  type: "number",
                  min: "3",
                  placeholder: "",
                  disabled: !_vm.singleLineMode,
                  title:
                    "Single Line Mode Only: How many values per user entry?",
                  id: "manual_cols"
                },
                domProps: { value: _vm.manualColumns },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.manualColumns = $event.target.value
                  }
                }
              })
            ])
          ])
        ],
        1
      ),
      _vm.parsedData
        ? _c("div", [
            _c("div", { staticClass: "green-divider" }),
            _c("h3", [
              _vm._v("Column Assignment "),
              _c("i", {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true }
                  }
                ],
                staticClass: "fa fa-question-circle clickable",
                attrs: { title: "Click for Help" },
                on: {
                  click: function($event) {
                    return _vm.$bvModal.show("modal-help-parse")
                  }
                }
              })
            ]),
            _vm._v(" Drag Parsed Data Columns to Fields "),
            _c("div", { staticClass: "data-container" }, [
              _c(
                "div",
                { staticClass: "data-col-container" },
                [
                  _c("div", { staticClass: "data-header-parsed" }, [
                    _vm._v("Unassigned Fields")
                  ]),
                  _c(
                    "draggable",
                    {
                      staticClass: "unassigned-cols",
                      attrs: {
                        draggable: ".data-col",
                        group: { name: "cols" }
                      },
                      model: {
                        value: _vm.cols,
                        callback: function($$v) {
                          _vm.cols = $$v
                        },
                        expression: "cols"
                      }
                    },
                    _vm._l(_vm.cols, function(col) {
                      return _c(
                        "div",
                        { key: col.id, staticClass: "data-col" },
                        [
                          _c("div", { staticClass: "data-col-header" }, [
                            _vm._v(_vm._s(col.title))
                          ]),
                          _c("div", { staticClass: "data-col-sample-header" }, [
                            _vm._v("Data Sample:")
                          ]),
                          _vm._l(col.sample, function(data, idx) {
                            return _c("div", { key: idx }, [
                              _vm._v(" " + _vm._s(data) + " ")
                            ])
                          }),
                          _c("div", { staticClass: "data-col-sample-header" }, [
                            _vm._v("Total:")
                          ]),
                          _vm._v(" " + _vm._s(col.data.length) + " Rows ")
                        ],
                        2
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ]),
            _vm._m(1),
            _c(
              "div",
              { staticClass: "data-container" },
              _vm._l(_vm.fieldCols, function(fieldCol) {
                return _c(
                  "div",
                  { key: fieldCol.id, staticClass: "data-col-container" },
                  [
                    _c("div", { staticClass: "data-header" }, [
                      _vm._v(_vm._s(fieldCol.title))
                    ]),
                    _c(
                      "draggable",
                      {
                        attrs: {
                          draggable: ".data-col",
                          group: { name: "cols" }
                        },
                        model: {
                          value: fieldCol.data,
                          callback: function($$v) {
                            _vm.$set(fieldCol, "data", $$v)
                          },
                          expression: "fieldCol.data"
                        }
                      },
                      _vm._l(fieldCol.data, function(col) {
                        return _c(
                          "div",
                          { key: col.id, staticClass: "data-col" },
                          [
                            _c("div", { staticClass: "data-col-header" }, [
                              _vm._v(_vm._s(col.title))
                            ]),
                            _c(
                              "div",
                              { staticClass: "data-col-sample-header" },
                              [_vm._v("Data Sample:")]
                            ),
                            _vm._l(col.sample, function(data, idx) {
                              return _c("div", { key: idx }, [
                                _vm._v(" " + _vm._s(data) + " ")
                              ])
                            }),
                            _c(
                              "div",
                              { staticClass: "data-col-sample-header" },
                              [_vm._v("Total:")]
                            ),
                            _vm._v(" " + _vm._s(col.data.length) + " Rows ")
                          ],
                          2
                        )
                      }),
                      0
                    )
                  ],
                  1
                )
              }),
              0
            )
          ])
        : _vm._e(),
      _vm.isDataInCols()
        ? _c(
            "div",
            { staticClass: "toolbar-small" },
            [
              _c(
                "b-button",
                { staticClass: "button", on: { click: _vm.clickOK } },
                [_vm._v("OK")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-help-input",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Data Input Help"
          }
        },
        [
          _c("div", { staticClass: "mb-2" }, [
            _vm._v(
              "Enter User Data into the large Textbox at the top of the form. Data can be separated by commas, semicolons, tabs, etc. By default each new row of data is expected to be on a NEW LINE."
            )
          ]),
          _c("h5", [_vm._v("Example #1: Multi-Line (default)")]),
          _c("div", [_vm._v("Bob Smith, Bob@hotmail.com, 0411111111")]),
          _c("div", [_vm._v("Roger Lemming, R-lemming@bmail.com, 042222222")]),
          _c("div", [_vm._v("Some Guy, guy@test.com, 04333333")]),
          _c("div", [_vm._v(" If your data is NOT on a new line")]),
          _c("div", { staticClass: "my-2" }, [
            _vm._v(
              " If your data is in a SINGLE LINE (i.e. does not have line returns between entries) then check the 'Single Line Mode' option and enter the number of columns that should be extracted for each entry:"
            )
          ]),
          _c("h5", [_vm._v("Example #2: Single Line")]),
          _c("div", [
            _vm._v(
              "Bob Smith, Bob@hotmail.com, 0411111111, Roger Lemming, R-lemming@bmail.com, 042222222, Some Guy, guy@test.com, 04333333"
            )
          ]),
          _c("div", [
            _vm._v(
              " For the above example we would set 3 as the number of columns as each entry has only Name, Email and Phone fields."
            )
          ]),
          _c(
            "b-button",
            {
              staticClass: "button",
              on: {
                click: function($event) {
                  return _vm.$bvModal.hide("modal-help-input")
                }
              }
            },
            [_vm._v("Close")]
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-help-parse",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Data Parsing Help"
          }
        },
        [
          _c("div", { staticClass: "mb-2" }, [
            _vm._v(
              "Your Data has been parsed into columns. Each column is numbered and shows a PREVIEW of the data it contains under the 'Data Sample' heading. If the Data is mixed together (i.e. usernames and email address appear in the same column), got back to the Input User Data section and try again. You may need to adjust the Items per Row if you are using single line mode."
            )
          ]),
          _c("div", [
            _vm._v(
              "If the Columns appear correct, they can be dragged into the area below the GREEN FIELD NAMES that match their contents. By default the first three columns will be placed under NAME, EMAIL and PHONE. These can be swapped around by dragging if they do not match."
            )
          ]),
          _c(
            "b-button",
            {
              staticClass: "button",
              on: {
                click: function($event) {
                  return _vm.$bvModal.hide("modal-help-parse")
                }
              }
            },
            [_vm._v("Close")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mr-4" }, [_c("h5", [_vm._v("Options:")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("i", { staticClass: "fa fa-angle-double-down" }),
      _c("i", { staticClass: "fa fa-angle-double-down" }),
      _c("i", { staticClass: "fa fa-angle-double-down" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }